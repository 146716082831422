/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React, { useRef, useState,useEffect} from "react";
import { useGLTF } from "@react-three/drei";
import { useSpring, animated } from '@react-spring/three'

function Tulip({hovered, ...props}) {
  const group = useRef();
  const { colors } = useSpring({
    colors: hovered  ?'gray' : 'black',
    // config: {duration:50}
    });
  const { nodes, materials } = useGLTF("/tulip1JOINEDfinal.glb");
  return (
    <group ref={group} scale={[1.1,1.4,1.1]} {...props} dispose={null}>
      <animated.mesh
        material-emissiveIntensity={.3}
        material-emissive  = {colors}
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={materials["Material.002"]}
        position={[-3.35, -1.55, -9.85]}
        rotation={[2.02, 1.57, 0]}
        scale={[-1.4, -0.42, -0.79]}
      />
      <animated.mesh
        material-emissiveIntensity={.3}
        material-emissive  = {colors}
        castShadow
        receiveShadow
        geometry={nodes.Cylinder003.geometry}
        material={materials["Material.019"]}
        position={[-1.08, 5.81, -3.54]}
        rotation={[-Math.PI, 0.83, -Math.PI]}
        scale={[0.23, 0.09, 0.23]}
      />
      <animated.mesh
        material-emissiveIntensity={.3}
        material-emissive  = {colors}
        castShadow
        receiveShadow
        geometry={nodes.Plane005.geometry}
        material={materials.Material}
        position={[-1.08, 6.38, -3.54]}
        rotation={[Math.PI / 2, 0, -2.56]}
        scale={[0.48, 0.48, 0.54]}
      />
    </group>
  );
}

useGLTF.preload("/tulip1JOINEDfinal.glb");

export default function Tulips({...props}) {
  const group = useRef();  
    const [hovered, setHover] = useState()

      useEffect(() => {
        document.body.style.cursor = hovered ? "pointer" : "auto"
      }, [hovered])

      const postions=[[1,2.8,-9.9],[.6, 2.1,-4.],[-4,2.7,-4]]
      const scales=[.8,1.05,.9]
      const rotations=[[0,Math.PI/2,0],[0,0,0],[0,-Math.PI/4,0],[0,.1,0]]
  return (

      <group ref={group} {...props}
        onPointerOver={(e) => setHover(true)}
        onPointerOut={(e) => setHover(false)}
      >
        {postions.map((positon,idx) =>( <Tulip  key={idx}  hovered={hovered} position={positon}  scale={scales[idx]}  rotation={rotations[idx]} />))}
      </group>


  )
}
